import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/redirect/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'redirect',
    redirect: '/home',
    component: HomeView,
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/HomeView.vue'),
        meta: {}
      }
    ]
  },
  {
    path: '/setting',
    name: 'setting',

    component: () => import('@/views/setting/SettingView.vue'),
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
